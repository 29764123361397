//https://github.com/vandit-bera/React-Routing
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ErrorPage from './component/ErrorPage';
import Navbar from "./component/Navbar";
import App from './App';
import Legal from "./component/Legal";
import Blog from "./component/Blog";
import Contact from "./component/Contact";
import Home from "./component/Home";
import Services from "./component/Services";
import Team from "./component/Team";
import Work_view from "./component/Work_view";
import Work_all from "./component/Work_all";
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import ThemeProvider from './context/ThemeProvider';

const fetchProjects = async () => {
const response = await fetch('https://discover.frankariza.com/app/v1/video-data-afp');
return await response.json();
};

const router = createBrowserRouter([
  {
    element: <App />,
     errorElement: <ErrorPage transition={`${'fadeIn'}`}/>,
    children: [
  {
    path: "/",
    element: <Home transition={`${'fadeIn'}`} />,
    loader: fetchProjects,
  },
  {
    path: "/servicios",
    element: <Services transition={`${'fadeIn'}`} />,
  },
  {
    path: "/catalogo",
    element: <Work_all transition={`${'fadeIn'}`} />,
    loader: fetchProjects,
    children: [
      {
        path: ":id",
        element: <Work_view transition={`${'fadeIn'}`} />,
        loader: fetchProjects,
        errorElement: <ErrorPage transition={`${'fadeIn'}`} />,
      },
    ],
  },
  {
    path: "/contacto",
    element: <Contact transition={`${'fadeIn'}`} />,
  },
  {
    path: "/legal",
    element: <Legal transition={`${'fadeIn'}`} />,
  },
]
    }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <ThemeProvider>

    <RouterProvider router={router} future={{ v7_startTransition: true }}>
<Navbar />
    </RouterProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
