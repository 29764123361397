import React,{useState, useRef, useEffect, useContext} from 'react'
import {SplitText} from "@cyriacbr/react-split-text" //Splitting tool mandatory for this component
import gsap from 'gsap'
import './titleAnimated.css'




function TitleAnimated(props) {
  var letterSpeed = props.letterSpeed;
  const CustomLevel = `h${props.level}`;

  function showNext(element){
    gsap.to(element.nextSibling,3,{delay:2,opacity:1,ease:'expo'});
  }
  function titleEnterEffects(element){
    var _done = element.querySelectorAll('.done');

    //Do not execute nothing if the class 'done' is present
    if(_done.length > 0){
      return Promise.resolve("failure");
      return;
    }

    var _words = [element];
    //console.log(_words);
    var _index = 0;
    var _elemIndex = 0;
    for (const word of _words) {

      var _w = word.querySelectorAll('span');

      for (const _letter of _w) {
        _index += letterSpeed + _index/100;

        var _animateWords = gsap.to(_letter, _index,{delay:0,opacity:1,onComplete:() => { _letter.classList.add('done','title_md','title_lg', 'title_xl') } });

      }

    }

    return Promise.resolve("success");

  }



  // Observer for title effects
  let optionsTitle = {
    rootMargin: '0px 0px 0px 0px',
    threshold: 0,
  };
  let callbackTitle = (entries, observerTitle) => {
    entries.forEach((entry) => {
      //console.log(entry.isIntersecting);

      var elem = entry.target ;

      if(entry.isIntersecting){

        var t = setTimeout(function(){
          titleEnterEffects(elem).then( (message) => {
            if(message ==='success'){
              //set the opacity to 1 for the next element
              //usually a div with classes "afterTitle opacity-0"
              if(props.afterTitle){
                showNext(elem);
              }
            }
          }
        );
      },400);

    }else{
      //not intersecting
    }
  });
};

let observerTitle = new IntersectionObserver(callbackTitle, optionsTitle);


useEffect((props)=> {

  let elements = document.querySelectorAll(".proj-title");
  for (let element of elements) {
    observerTitle.observe(element);
  }

});


return (

  <CustomLevel id={props.id_t} className={props.classes} style={{transform:'scale('+props.scale+')',transformOrigin:'bottom left', color:props.color}}>
    <SplitText className="ttl"
        LineWrapper={({ wordIndex, children }) => (
          <span className="ttl_wrapper">
          {children}
          </span>
        )}
    >
      {props.title}

    </SplitText>
  </CustomLevel>

)
}
export default TitleAnimated
