import React from 'react'

function Footer(props) {
  return (
    <div className={"bg-gray-100 text-gray-800 p-6 text-center text-sm " + props.transition}>
  &copy; <span className="magenta">AF Pictures</span> 2024 &bull; <a className="link" href="/legal">Legal</a> &bull; <a className="link magenta" href="/contacto">Contacto</a> &bull; <span className="magenta">comercial@afpictures.com</span> <br/> <a style={{color:'#c5c5c5'}} className="text-sm pb-2" href="https://remiblot.com">Crafted by Rémi Blot</a>
</div>
  )
}

export default Footer
